<template>
  <div class="cgx">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
      <el-breadcrumb-item :to="{ path: '/chome' }">企业首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/personindex' }">供需交易列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="sx">
      <formlist class="fll sxx" :arr="shuaixuan">
        <el-button type="primary" size="mini" style="margin: 8px 0 0 8px">搜索</el-button>
        <el-button type="" size="mini" style="margin: 8px 0 0 8px">重置</el-button>
      </formlist>
      <template>
        <el-table
            :data="tableData"
            :show-header="false"
            style="width: 100%;">
          <el-table-column
              prop="type"
              label=""
              align="center"
              width="130">
          </el-table-column>
          <el-table-column
              prop="title"
              align="center"
              label="">
          </el-table-column>
          <el-table-column
              prop="time"
              align="center"
              label=""
              width="250">
          </el-table-column>
        </el-table>
      </template>
      <div class="fenye">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            page-size="15"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import formlist from "../../components/formlist";
export default {
  name: "cgx",
  data(){
    return {
      tableData:[{
        type:'个人消息',
        title:'标题1111111111111',
        time:'2023-12-12'
      }],
      pageNo:1,
      total:1,
      shuaixuan:[{
        type:'select',
        inputTitle:'类型',
        options:[{
          optionTitle:'111',
          inputValue:'222'
        }]
      },{
        type:'text',
        inputTitle:'行业',
      },{
        type:'text',
        inputTitle:'价格区间（元）',
      },{
        type:'text',
        inputTitle:'出售状态',
      },{
        type:'date',
        inputTitle:'发布时间',
        inputName:'time1|time2'
      },{
        type:'text',
        inputTitle:'产品名称',
      }]
    }
  },
  methods:{
    handleCurrentChange(val) {
      this.pageNo=val
      this.loadlist()
    },
    loadlist(){

    }
  },
  components:{
    formlist
  }
}
</script>

<style lang="scss" scoped>
  .cgx{
    width: 1200px;
    margin: 0 auto;
    padding-top: 30px;
  }
  .sxx{
    padding: 30px;
    /deep/ .list{
      display: block!important;
    }
    /deep/ .label{
      min-width: 120px;
    }
  }
  .fenye{
    text-align: center;
    padding: 30px 0;
  }
</style>